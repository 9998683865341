import './App.css';
import { Box, Button, createTheme, ThemeProvider, Typography } from '@mui/material';
import colors from './lib/colors';
import { useEffect, useState } from 'react';
import { SignIn } from './components/SignIn';
import { getAuth } from 'firebase/auth';
import { Route, Routes, BrowserRouter, useNavigate } from 'react-router';
import { LeaseAnalysisView } from './components/LeaseAnalysisView';
import { Page } from './components/Page';
import { AuthValidator } from './AuthValidator';
import { AcceptInvite } from './components/AcceptInvite';
import { ManageOrganization } from './components/ManageOrganization';
import { BackendAPI } from './lib/backendAPI';

// const analytics = getAnalytics(firebaseApp);

let originURL: string | undefined = process.env.REACT_APP_API_ORIGIN_URL;
if (originURL === undefined) {
  originURL = "http://127.0.0.1:8000"
}
export const ORIGIN: string = originURL;

function App() {
  const auth = getAuth();
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none"
      },
      fontFamily: "Roboto, Arial",
      allVariants: {
        color: colors.greyDarker
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            // "&:hover": {
            //   backgroundColor: colors.violetLighter,
            // },
          }
        },
        defaultProps: {
          disableElevation: true
        }
      },
    },
    palette: {
      primary: {
        main: colors.violetDark,
      },
      secondary: {
        main: colors.violetLightest,
      },
      error: {
        main: colors.red,
        contrastText: "white"
      }
    }
  });
  const [knownAuthState, setKnownAuthState] = useState(false);
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setKnownAuthState(true);
      if (user == null) {
        setHasAccess(false);
      }
      else {
        BackendAPI.GetUser(user).then((propayaUser) => setHasAccess(propayaUser !== null && propayaUser.organization_id !== null))
      }
      console.log("Firebase user: ");
      console.log(user);
      //check user http
    });
    return unsubscribe;
  }, []);

  return (
    knownAuthState ?
      <ThemeProvider theme={theme} >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <AuthValidator>
                <Page />
              </AuthValidator>
            }>
              <Route index element={hasAccess === null || hasAccess ?
                <LeaseAnalysisView /> :
                <Box margin={theme.spacing(4)}>
                  <Typography variant='h5'>This user is not currently associated with an organization.</Typography>
                  <Button variant='contained' onClick={() => auth.signOut().then(() => window.location.reload())} >Sign out</Button>
                </Box>
              } />
              <Route path="organization" element={
                <ManageOrganization />
              } />
            </Route>
            <Route path="login" element={
              <SignIn />
            } />
            <Route path="accept-invite" element={
              <AcceptInvite />
            } />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      :
      <div />
  );
}

export default App;
