import { Google } from "@mui/icons-material";
import { Box, Button, Grid2, TextField, Typography, useTheme } from "@mui/material";
import { browserPopupRedirectResolver, getAuth, GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo, signInWithEmailAndPassword, AuthErrorCodes, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import propayaLogo from '../images/Propaya_Logo.png';
import { ORIGIN } from "../App";
import { VERIFY_INVITE } from "../lib/apiPaths";
import { InviteVerification } from "../lib/interfaces";
import { Loading } from "./Loading";
import { BackendAPI } from "../lib/backendAPI";

export function AcceptInvite() {
    const auth = getAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const inviteId = searchParams.get("t") ?? "";
    const [firstNameField, setFirstNameField] = useState("");
    const [lastNameField, setLastNameField] = useState("");
    const [emailField, setEmailField] = useState("");
    const [signInPasswordField, setSignInPasswordField] = useState("");
    const [createAccountPasswordField, setCreateAccountPasswordField] = useState("");
    const [createAccountPasswordConfirmationField, setCreateAccountPasswordConfirmationField] = useState("");
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [submitError, setSubmitError] = useState(false);
    const [inviteValid, setInviteValid] = useState<boolean | null>(null);
    const [invite, setInvite] = useState<InviteVerification>({ email: "", userExists: false })

    async function VerifyInvite() {
        if (inviteId === "") {
            setInviteValid(false);
            return;
        }
        fetch(ORIGIN + VERIFY_INVITE + `?t=${inviteId}`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            mode: "cors",
        }).then((response) => {
            if (!response.ok) {
                setInviteValid(false);
                return Promise.reject("Invite Invalid")
            }
            setInviteValid(true);
            return response.json();
        }).then((verifyInviteResponse: InviteVerification) => {
            setIsCreatingAccount(!verifyInviteResponse.userExists);
            setEmailField(verifyInviteResponse.email);
            setInvite(verifyInviteResponse);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        //verify invite
        VerifyInvite();
    }, []);

    function handleGoogleSignIn() {
        const googleProvider = new GoogleAuthProvider();
        signInWithPopup(auth, googleProvider, browserPopupRedirectResolver).then((userCredential) => {
            if (userCredential.user.email !== invite.email) {
                alert("Google email does not match invite email");
                auth.signOut();
                navigate(0);
                return Promise.reject("Google email does not match invite email")
            }
            else {
                const additionalUserInfo = getAdditionalUserInfo(userCredential);
                //create option-select fulfilled promise
                let userCreated = Promise.resolve(new Response());
                // if new user, create new user in db
                if (additionalUserInfo !== null && additionalUserInfo.isNewUser) {
                    userCreated = BackendAPI.CreateUserFromUserCredential(userCredential);
                }
                // accept invite
                return userCreated.then(() => BackendAPI.AcceptInvite(inviteId, userCredential.user));
            }
        }).then((response) => {
            navigate("/");
        }).catch((reason) => {
            alert("Login Failed");
        });
    }

    function handlePasswordSignIn() {
        signInWithEmailAndPassword(auth, emailField, signInPasswordField).then((userCredential) => {
            setSubmitError(false);
            setErrorText("");
            return BackendAPI.AcceptInvite(inviteId, userCredential.user);
        }).then(() => {
            navigate("/");
        }).catch((error) => {
            console.log(error);
            if (error.code === AuthErrorCodes.INVALID_IDP_RESPONSE) { // user not found i.e. new user
                setErrorText("Incorrect Email or Password");
                setSubmitError(true);
            }
        });
    }

    function handlePasswordCreateAccount() {
        if (createAccountPasswordField !== createAccountPasswordConfirmationField) {
            setErrorText("Passwords do not match.");
            setSubmitError(true);
            return; //error, passwords don't match
        }
        createUserWithEmailAndPassword(auth, emailField, createAccountPasswordField).then((userCredential) => {
            setErrorText("");
            setSubmitError(false);
            updateProfile(userCredential.user, { displayName: (firstNameField + " " + lastNameField) })
            return BackendAPI.CreateUserFromUserCredential(userCredential).then(() => BackendAPI.AcceptInvite(inviteId, userCredential.user));
        }).then(() => {
            navigate("/");
        }).catch((error) => {
            console.log(error);
            console.log(error.code);
            setErrorText("Password must contain an uppercase letter, a special character, and be at least 6 characters.");
            setSubmitError(true);
        });
    }

    return (
        <>
            {
                inviteValid === null ? <Loading /> :
                    (inviteValid ?
                        <Box width={"100vw"} height={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <Box width={"40%"} display={"flex"} flexDirection={"column"} marginTop={"15vh"} alignItems={"center"} gap={theme.spacing(4)}>
                                <Box component="img" src={propayaLogo} sx={{ height: "100px" }} />
                                <Typography variant="h2" textAlign={"center"}>
                                    Sign In:
                                </Typography>
                                <Button sx={{ maxWidth: 200 }} variant="outlined" startIcon={<Google />} id="signinwithgoogleid" onClick={handleGoogleSignIn}>
                                    Sign in with Google
                                </Button>
                                <Grid2 container spacing={theme.spacing(2)} maxWidth={600}>
                                    <Grid2 size={12}>
                                        <TextField
                                            required
                                            disabled
                                            label="Email"
                                            autoComplete="username"
                                            value={emailField}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setEmailField(event.target.value)
                                            }}
                                            fullWidth
                                            error={submitError}
                                        />
                                    </Grid2>
                                    {
                                        !isCreatingAccount ?
                                            <Grid2 size={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label="Password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    value={signInPasswordField}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setSignInPasswordField(event.target.value)
                                                    }}
                                                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                                        if (event.key === "Enter") handlePasswordSignIn();
                                                    }}
                                                    error={submitError}
                                                    helperText={errorText}
                                                />
                                                <Typography color={"#5388F2"} fontWeight={500} sx={{ cursor: "pointer" }} onClick={() => {
                                                    setIsCreatingAccount(true);
                                                    setCreateAccountPasswordField(signInPasswordField);
                                                    setSignInPasswordField("");
                                                    setErrorText("");
                                                    setSubmitError(false);
                                                }}
                                                >
                                                    Create an Account
                                                </Typography>
                                            </Grid2>
                                            :
                                            <>
                                                <Grid2 size={12}>
                                                    <TextField
                                                        required
                                                        label="Password"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        value={createAccountPasswordField}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setCreateAccountPasswordField(event.target.value)
                                                        }}
                                                        fullWidth
                                                        error={submitError}
                                                    />
                                                </Grid2>
                                                <Grid2 size={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        label="Confirm Password"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        value={createAccountPasswordConfirmationField}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setCreateAccountPasswordConfirmationField(event.target.value)
                                                        }}
                                                        error={submitError}
                                                        helperText={errorText}
                                                    />
                                                </Grid2>
                                                <Grid2 size={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="First Name"
                                                        autoComplete="given-name"
                                                        value={firstNameField}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setFirstNameField(event.target.value)
                                                        }}
                                                    />
                                                </Grid2>
                                                <Grid2 size={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Last Name"
                                                        autoComplete="family-name"
                                                        value={lastNameField}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setLastNameField(event.target.value)
                                                        }}
                                                        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                                            if (event.key === "Enter") handlePasswordCreateAccount();
                                                        }}
                                                    />
                                                </Grid2>
                                            </>
                                    }
                                </Grid2>
                                <Button variant="contained" onClick={isCreatingAccount ? handlePasswordCreateAccount : handlePasswordSignIn}>{isCreatingAccount ? "Create Account" : "Login"}</Button>
                            </Box>
                        </Box>
                        :
                        <Typography>Invite Invalid or Expired</Typography>
                    )
            }
        </>
    );
}