import { Box, Button, CircularProgress, Grid2, IconButton, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { PropayaUser } from "../lib/interfaces";
import { ORIGIN } from "../App";
import { ORG_USERS } from "../lib/apiPaths";
import { getAuth } from "firebase/auth";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Add, Delete } from "@mui/icons-material";
import { modalStyle } from "../lib/styles";
import { UserRole, UserStatus } from "../lib/enums";
import { BackendAPI } from "../lib/backendAPI";

const emptyUser = {
    id: null,
    organization_id: null,
    email: "",
    role: UserRole.USER,
    status: UserStatus.INACTIVE,
    firebase_id: ""
}

export function ManageOrganization() {
    const auth = getAuth();
    const theme = useTheme();
    const [users, setUsers] = useState<PropayaUser[] | null>(null);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [inviteEmailField, setInviteEmailField] = useState("");
    const [inviteRoleField, setInviteRoleField] = useState<UserRole>(UserRole.USER);
    const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
    const [modalUser, setModalUser] = useState<PropayaUser>(emptyUser);
    const [sendingInvite, setSendingInvite] = useState(false);

    const columns: GridColDef[] = [
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
        },
        {
            field: "manage", // no actual field
            headerName: "",
            renderCell: (params) => {
                if (params.row.firebase_id === auth.currentUser?.uid) return <></>;
                return (
                    <IconButton onClick={() => {
                        // modal for removing this user
                        if (users === null) return;
                        setModalUser(users.find((value) => value.id === params.id) ?? emptyUser);
                        setRemoveUserModalOpen(true);
                    }}>
                        <Delete />
                    </IconButton>
                );
            }
        }
    ];

    function handleCloseInviteModal() {
        setInviteModalOpen(false);
    }

    function handleCloseRemoveUserModal() {
        setRemoveUserModalOpen(false);
    }

    function FetchUsers() {
        if (auth.currentUser === null) return;
        auth.currentUser.getIdToken().then((idToken) => fetch(ORIGIN + ORG_USERS, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            mode: "cors",
        })).then((response) => response.json())
            .then((fetchedUsers: PropayaUser[]) => setUsers(fetchedUsers))
            .catch(() => {
                console.log("Failed to fetch");
            });
    }

    async function handleInviteUser() {
        if (auth.currentUser === null) return;
        setSendingInvite(true)
        BackendAPI.InviteUser(inviteEmailField, inviteRoleField, auth.currentUser).then(() => {
            setSendingInvite(false);
            setInviteModalOpen(false);
            setInviteEmailField("");
            setInviteRoleField(UserRole.USER);
        });
    }

    async function handleRemoveUser() {
        if (auth.currentUser === null || modalUser.id === null || users === null) return;
        BackendAPI.RemoveUserFromOrganization(modalUser.id, auth.currentUser);
        setRemoveUserModalOpen(false);
        const newUsers = users.splice(users.indexOf(modalUser), 1);
        setUsers(newUsers);
    }

    useEffect(() => {
        if (users === null) FetchUsers();
    }, [users])

    return (
        <Box margin={theme.spacing(4)} >
            <Modal open={inviteModalOpen} onClose={handleCloseInviteModal}>
                <Box sx={modalStyle}>
                    <Typography variant="h4" sx={{ marginBottom: theme.spacing(2) }}>Invite User</Typography>
                    <Grid2 container spacing={theme.spacing(2)}>
                        <Grid2 size={12}>
                            <TextField
                                required
                                label="Email"
                                value={inviteEmailField}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setInviteEmailField(event.target.value)
                                }}
                                fullWidth
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <Select
                                fullWidth
                                value={inviteRoleField}
                                onChange={(event: SelectChangeEvent) => {
                                    setInviteRoleField(event.target.value as UserRole);
                                }}
                            >
                                <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
                                <MenuItem value={UserRole.USER}>User</MenuItem>
                            </Select>
                        </Grid2>
                        <Grid2 size={6}>
                            <Button variant="outlined" fullWidth onClick={() => {
                                setInviteEmailField("");
                                setInviteRoleField(UserRole.USER);
                                setInviteModalOpen(false);
                            }}>
                                Cancel
                            </Button>
                        </Grid2>
                        <Grid2 size={6}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={handleInviteUser}
                                disabled={sendingInvite}
                            >
                                Invite
                            </Button>
                            {
                                sendingInvite &&
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: 'absolute',
                                        left: '70.5%',
                                        top: "79%"
                                    }}
                                />
                            }
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>
            <Modal open={removeUserModalOpen} onClose={handleCloseRemoveUserModal}>
                <Box sx={modalStyle}>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>{"Remove \"" + modalUser.email + "\" from your organization?"}</Typography>
                    <Grid2 container spacing={theme.spacing(2)}>
                        <Grid2 size={6}>
                            <Button variant="outlined" fullWidth onClick={() => {
                                setModalUser(emptyUser);
                                setRemoveUserModalOpen(false);
                            }}>
                                Cancel
                            </Button>
                        </Grid2>
                        <Grid2 size={6}>
                            <Button variant="contained" color="error" fullWidth onClick={handleRemoveUser}>
                                Remove
                            </Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>
            <Typography variant="h2">Users</Typography>
            <Button startIcon={<Add />} variant="contained" onClick={() => setInviteModalOpen(true)} >Invite</Button>
            <DataGrid
                columns={columns}
                rows={users ?? []}
                hideFooter={(users?.length ?? 0) < 25}
                disableRowSelectionOnClick
                sx={{
                    marginTop: theme.spacing(2)
                }}
            />
        </Box>
    );
}