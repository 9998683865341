import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { PropayaAppBar } from "./PropayaAppBar";
import { CloudUploadOutlined } from "@mui/icons-material";
import { VisuallyHiddenInput } from "../lib/utils";
import { AnalysisDataDisplay } from "./AnalysisDataDisplay";
import { useCallback, useState } from "react";
import { AnalysisResult } from "../lib/interfaces";
import { BlockBlobClient } from "@azure/storage-blob";
import colors from "../lib/colors";
import { useDropzone } from "react-dropzone";
import { getAuth } from "firebase/auth";

enum View {
    NotUploaded,
    Uploading,
    Processing,
    Preview
}

export function LeaseAnalysisView() {
    const auth = getAuth();
    const theme = useTheme();
    const [selectedFile, setSelectedFile] = useState<File>(new File([], "No file selected"));
    const [data, setData] = useState<AnalysisResult>();
    const [view, setView] = useState<View>(View.NotUploaded);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    async function FetchData(org: string, blobName: string) {
        const requestBody = {
            "organization": org,
            "blobName": blobName
        };
        const response = await fetch("https://leaseanalysisfunctions.azurewebsites.net/api/analyze", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
            // credentials: "include",
            body: JSON.stringify(requestBody)
        });
        setData(await response.json());
    }

    async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null || event.target.files.length === 0) return;
        FileChosen(event.target.files[0])
    }

    async function UploadFile(file: File) {
        if (auth.currentUser === null) return;
        const response = await fetch(`https://leaseanalysisfunctions.azurewebsites.net/api/fetch_sas?blobName=${auth.currentUser.uid}/${encodeURIComponent(file.name)}`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
            },
        });
        const SAS = await response.json();
        const blockBlobClient = new BlockBlobClient(SAS);
        try {
            await blockBlobClient.uploadData(file);
            return true;
        }
        catch {
            return false;
        }
    }

    async function FileChosen(file: File) {
        if (auth.currentUser === null) return;
        setView(View.Uploading)
        setSelectedFile(file);
        if (!await UploadFile(file)) return alert("Upload failed!");
        setView(View.Processing)
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
        FetchData("test-org", `${auth.currentUser.uid}/${file.name}`);
        setView(View.Preview);
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        // if (acceptedFiles.length == 0) return setUploadErrorText("Invalid file, please upload correct file type.");
        FileChosen(acceptedFiles[0])
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'application/pdf': [".pdf"],
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            "image/heic": []
        },
        // disabled: (uploadState != UploadState.NotUploaded)
    });

    return (
        <Box>
            <PropayaAppBar />
            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} height={"calc(100vh - 84px)"} width={"100%"} bgcolor={colors.SchemesSurfaceDim}>
                {
                    view === View.NotUploaded &&
                    <Box
                        {...getRootProps()}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent={"center"}
                        gap={theme.spacing(2)}
                        margin={"auto"}
                        sx={{
                            background: colors.SchemesSurfaceContainerLow,
                            padding: theme.spacing(4),
                            borderRadius: theme.spacing(4),
                            width: "50vw",
                            height: "40vh"
                        }}
                    >
                        <CloudUploadOutlined color="primary" sx={{ width: 101, height: 101 }} />
                        <Typography>Drop your file here or</Typography>
                        <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                        <Button component="label" variant="contained" color="secondary">
                            Browse
                        </Button>
                        <Typography textAlign="center" variant="body2" color="grey">Accepted file types: .pdf, .png, .jpg, .jpeg. <br /> Maximum file size 10 MB </Typography>
                    </Box>
                }
                {
                    view === View.Uploading &&
                    <Box margin="auto" display={"flex"} flexDirection={"column"} alignItems={"center"} bgcolor={"white"} borderRadius={theme.spacing(4)} padding={theme.spacing(16)}>
                        <Typography variant='h3' marginBottom={theme.spacing(4)}>Uploading...</Typography>
                        <CircularProgress />
                    </Box>
                }
                {
                    (view === View.Preview || view === View.Processing) &&
                    <Box display={"flex"} flexDirection={"row"} height={"100%"} width={"100vw"}>
                        <Box height={"100%"} flex={1}>
                            {previewUrl ? (
                                <iframe
                                    src={previewUrl}
                                    width="100%"
                                    height="100%"
                                    title="PDF Preview"
                                />
                            ) : (
                                <Typography>Loading preview...</Typography>
                            )}
                        </Box>
                        <Box height={"calc(100vh - 84px)"} flex={1} overflow={"auto"} >
                            {
                                view === View.Processing || data === undefined ?
                                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"} >
                                        <Typography variant='h3' marginBottom={theme.spacing(4)}>Processing...</Typography>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <AnalysisDataDisplay data={data} />
                            }
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    );
}