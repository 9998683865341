import { AuthErrorCodes, browserPopupRedirectResolver, createUserWithEmailAndPassword, getAdditionalUserInfo, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, updateCurrentUser, updateProfile } from "firebase/auth";
import { Box, Button, Grid2, TextField, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { Google } from "@mui/icons-material";
import propayaLogo from '../images/Propaya_Logo.png';
import { useState } from "react";
import colors from "../lib/colors";
import { PropayaUser } from "../lib/interfaces";
import { UserRole, UserStatus } from "../lib/enums";
import { ORIGIN } from "../App";
import { USER } from "../lib/apiPaths";
import { BackendAPI } from "../lib/backendAPI";

export function SignIn() {
    const auth = getAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const [firstNameField, setFirstNameField] = useState("");
    const [lastNameField, setLastNameField] = useState("");
    const [emailField, setEmailField] = useState("");
    const [signInPasswordField, setSignInPasswordField] = useState("");
    const [createAccountPasswordField, setCreateAccountPasswordField] = useState("");
    const [createAccountPasswordConfirmationField, setCreateAccountPasswordConfirmationField] = useState("");
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [submitError, setSubmitError] = useState(false);

    function handleGoogleSignIn() {
        const googleProvider = new GoogleAuthProvider();
        signInWithPopup(auth, googleProvider, browserPopupRedirectResolver).then((userCredential) => {
            console.log(getAdditionalUserInfo(userCredential))
            const additionalUserInfo = getAdditionalUserInfo(userCredential);
            if (additionalUserInfo !== null && additionalUserInfo.isNewUser) {
                // create new user in db
                return BackendAPI.CreateUserFromUserCredential(userCredential);
            }
        }).then(() => {
            navigate("/");
        }).catch((reason) => {
            alert("Login Failed");
        });
    }

    // if user doesn't exist, show create account fields
    function handlePasswordSignIn() {
        signInWithEmailAndPassword(auth, emailField, signInPasswordField).then((userCredential) => {
            setSubmitError(false);
            setErrorText("");
            navigate("/");
        }).catch((error) => {
            console.log(error);
            if (error.code === AuthErrorCodes.INVALID_IDP_RESPONSE) { // user not found i.e. new user
                setErrorText("Incorrect Email or Password");
                setSubmitError(true);
            }
        });
    }

    function handlePasswordCreateAccount() {
        if (createAccountPasswordField !== createAccountPasswordConfirmationField) {
            setErrorText("Passwords do not match.");
            setSubmitError(true);
            return; //error, passwords don't match
        }
        createUserWithEmailAndPassword(auth, emailField, createAccountPasswordField).then((userCredential) => {
            setErrorText("");
            setSubmitError(false);
            updateProfile(userCredential.user, { displayName: (firstNameField + " " + lastNameField) })
            return BackendAPI.CreateUserFromUserCredential(userCredential);
        }).then(() => {
            navigate("/");
        }).catch((error) => {
            console.log(error);
            console.log(error.code);
            setErrorText("Password must contain an uppercase letter, a special character, and be at least 6 characters.");
            setSubmitError(true);
        });
    }

    return (
        <Box width={"100vw"} height={"100vh"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box width={"40%"} display={"flex"} flexDirection={"column"} marginTop={"15vh"} alignItems={"center"} gap={theme.spacing(4)}>
                <Box component="img" src={propayaLogo} sx={{ height: "100px" }} />
                <Typography variant="h2" textAlign={"center"}>
                    Sign In:
                </Typography>
                <Button sx={{ maxWidth: 200 }} variant="outlined" startIcon={<Google />} id="signinwithgoogleid" onClick={handleGoogleSignIn}>
                    Sign in with Google
                </Button>
                <Grid2 container spacing={theme.spacing(2)} maxWidth={600}>
                    <Grid2 size={12}>
                        <TextField
                            required
                            label="Email"
                            autoComplete="username"
                            value={emailField}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmailField(event.target.value)
                            }}
                            fullWidth
                            error={submitError}
                        />
                    </Grid2>
                    {
                        !isCreatingAccount ?
                            <Grid2 size={12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={signInPasswordField}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSignInPasswordField(event.target.value)
                                    }}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                        if (event.key === "Enter") handlePasswordSignIn();
                                    }}
                                    error={submitError}
                                    helperText={errorText}
                                />
                                <Typography color={"#5388F2"} fontWeight={500} sx={{ cursor: "pointer" }} onClick={() => {
                                    setIsCreatingAccount(true);
                                    setCreateAccountPasswordField(signInPasswordField);
                                    setSignInPasswordField("");
                                    setErrorText("");
                                    setSubmitError(false);
                                }}
                                >
                                    Create an Account
                                </Typography>
                            </Grid2>
                            :
                            <>
                                <Grid2 size={12}>
                                    <TextField
                                        required
                                        label="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={createAccountPasswordField}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCreateAccountPasswordField(event.target.value)
                                        }}
                                        fullWidth
                                        error={submitError}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Confirm Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={createAccountPasswordConfirmationField}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setCreateAccountPasswordConfirmationField(event.target.value)
                                        }}
                                        error={submitError}
                                        helperText={errorText}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        autoComplete="given-name"
                                        value={firstNameField}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setFirstNameField(event.target.value)
                                        }}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        autoComplete="family-name"
                                        value={lastNameField}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setLastNameField(event.target.value)
                                        }}
                                        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                            if (event.key === "Enter") handlePasswordCreateAccount();
                                        }}
                                    />
                                </Grid2>
                            </>
                    }
                </Grid2>
                <Button variant="contained" onClick={isCreatingAccount ? handlePasswordCreateAccount : handlePasswordSignIn}>{isCreatingAccount ? "Create Account" : "Login"}</Button>
            </Box>
        </Box>
    );
}