import { AccountCircle } from "@mui/icons-material";
import { AppBar, Box, Button, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import propayaLogo from '../images/Propaya_Logo.png';
import { getAuth } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router";

export function PropayaAppBar() {
    const auth = getAuth();
    const displayName = auth.currentUser?.displayName;
    const navigate = useNavigate();
    const [viewMenuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const viewMenuOpen = Boolean(viewMenuAnchorEl);

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    function SignOut() {
        auth.signOut().then(() => {
            navigate("/login");
        });
    }

    async function GoogleAuthTest() {
        if (auth.currentUser === null) return;

        auth.currentUser.getIdToken().then((idToken) => {
            console.log(idToken)
            return fetch("http://127.0.0.1:8000/", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + idToken
                }
            })
                .then(response => response.json())
                .then(result => console.log(result))
        });
    }

    return (
        <AppBar position='sticky' >
            <Menu
                anchorEl={viewMenuAnchorEl}
                open={viewMenuOpen}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => navigate("/organization")} disableRipple>
                    My Organization
                </MenuItem>
                <MenuItem onClick={SignOut} disableRipple>
                    Sign Out
                </MenuItem>
            </Menu>
            <Toolbar sx={{ bgcolor: "white", height: "84px" }}>
                <Box display={"flex"} flexDirection={"row"} width={"100%"}>
                    <Box flex={1}>
                        <Box component="img" src={propayaLogo} sx={{ height: "48px" }} />
                    </Box>
                    <Box width={"100%"} flex={1}>
                        <Typography variant='h4' textAlign={"center"}>Lease Analysis</Typography>
                    </Box>
                    <Box flex={1} display={"flex"}>
                        <Box flex={1} />
                        <Button startIcon={<AccountCircle />} variant='outlined' onClick={handleClickMenu} >
                            {
                                displayName === null || displayName === undefined || displayName === "" ? "Account" : displayName.split(" ")[0]
                            }
                        </Button>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    )
}