import { getAuth, User } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Loading } from "./components/Loading";

interface AuthValidatorProps {
    children: ReactNode
}

export function AuthValidator( {children}: AuthValidatorProps ) {
    const auth = getAuth();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

    useEffect(() => {
        setIsLoggedIn(auth.currentUser !== null);
    }, [isLoggedIn]);

    return (
        <>
            {isLoggedIn === null ? <Loading/> : ( isLoggedIn ? children : <Navigate to={"/login"}/>)}
        </>
    );
}