import { User, UserCredential } from "firebase/auth";
import { PropayaUser } from "./interfaces";
import { UserRole, UserStatus } from "./enums";
import { ORIGIN } from "../App";
import { ACCEPT_INVITE, INVITE_USER, ORG_USER, USER } from "./apiPaths";

export class BackendAPI {
    static CreateUserFromUserCredential(userCredential: UserCredential) {
        const newUser: PropayaUser = {
            id: null,
            organization_id: null,
            email: userCredential.user.email!,
            role: UserRole.USER,
            status: UserStatus.INACTIVE,
            firebase_id: userCredential.user.uid
        }
        // get id token, use token for create user request, return promise of result
        return userCredential.user.getIdToken().then((idToken) => fetch(ORIGIN + USER, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            mode: "cors",
            body: JSON.stringify(newUser)
        }))
    }

    static AcceptInvite(inviteId: string, firebaseUser: User) {
        return firebaseUser.getIdToken().then((idToken) => fetch(ORIGIN + ACCEPT_INVITE + `?t=${inviteId}`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            mode: "cors",
        }));
    }

    static InviteUser(email: string, role: UserRole, firebaseUser: User){
        const body = {
            email: email,
            role: role
        };
        return firebaseUser.getIdToken().then((idToken) => fetch(ORIGIN + INVITE_USER, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            mode: "cors",
            body: JSON.stringify(body)
        }));
    }

    static RemoveUserFromOrganization(userId: number, firebaseUser: User){
        return firebaseUser.getIdToken().then((idToken) => fetch(ORIGIN + ORG_USER + `?user_id=${userId}`, {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            mode: "cors",
        }));
    }

    static GetUser(firebaseUser: User){
        return firebaseUser.getIdToken().then((idToken) => fetch(ORIGIN + USER, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${idToken}`
            },
            mode: "cors",
        })).then((response) => response.json() as Promise<PropayaUser | null>);
    }
}
